<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="custom-viewer"
  >
    <v-card class="custom-detail" flat>
      <v-card-title class="card-title">
        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnCancel="true"
          @onChangeCancel="cancel()"
          colorBtnCancel="white"
          style="position: relative; z-index: 1"
        />
      </v-card-title>

      <v-card-text class="content">
        <img :src="src | imageDefault" v-if="!notify" />
        <img :src="src" v-if="notify" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomButtonAction from "../custom-button-action/CustomButtonAction.vue";

export default {
  name: "CustomViewerImage",
  data: () => ({
    viewModal: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    src: {},
    notify: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
  },
  components: {
    CustomButtonAction,
  },
  methods: {
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:src", false);
    },
  },
};
</script>

<style lang="scss">
.custom-viewer {
  .custom-detail {
    .card-title {
      background: #121212;
    }

    .content {
      background: #121212;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      padding: 0px;

      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  }
}
</style>
