<template>
  <div class="mobile-notification">
    <v-row v-if="notify.length === 0">
      <v-col cols="12">
        <div class="message-error">No tiene notificaciones registradas</div>
      </v-col>
    </v-row>

    <v-list three-line v-for="(item, i) in notify" :key="i">
      <v-list-item @click="viewNotify(item)">
        <v-list-item-content>
          <span class="card-subtitle">
            {{ item.createdAt | dateTime }}
          </span>

          <v-list-item-title class="second--text card-title">
            {{ item.title }}
          </v-list-item-title>

          <v-list-item-subtitle class="card-message">
            {{ item.message }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            icon
            v-if="item.check === 1 && !item.image"
            class="icon-warning"
          >
            <v-icon style="font-size: 30px"> mdi-information-outline </v-icon>
          </v-btn>
          <v-btn icon v-if="item.check === 0 && item.status === 1">
            <v-icon style="font-size: 30px">
              mdi-chevron-right-circle-outline
            </v-icon>
          </v-btn>
          <img :src="item.image" v-if="item.image && item.status === 2" />
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <CustomViewerImage
      :dialog.sync="modalImage"
      :src.sync="image"
      :notify="true"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NotificationServices from "../../../../core/services/notification-services";
import NotifyStorage from "../../../../core/storage/notification-storage";
import CustomViewerImage from "../../../../shared/components/custom-viewer-image/custom-viewer-image.vue";

export default {
  name: "Notification",
  data: () => ({
    modalImage: false,
    image: null,
  }),
  computed: {
    ...mapState(["notify", "user"]),
  },
  components: {
    CustomViewerImage,
  },
  created() {
    if (this.user.connect) {
      this.getAllNotify(this.user.connect);
    }
  },
  methods: {
    ...mapActions(["getNotify", "getCountNotify"]),
    getAllNotify(userConnect) {
      const { notificatioMobileAll } = NotificationServices();
      const { notificationAdd } = NotifyStorage();
      notificatioMobileAll(userConnect).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            notificationAdd(item);
          });
          this.getNotify();
        }
      });
    },
    viewNotify(data) {
      const { notificatioMobileCheck } = NotificationServices();
      const { notificationAdd } = NotifyStorage();
      if (data.check === 1) {
        notificatioMobileCheck(data.id).then((resp) => {
          const response = resp.data;
          if (response.code === 200) {
            this.getCountNotify({ count: response.data.count });
            data.check = 0;
            notificationAdd(data);
            this.getNotify();

            if (data.status !== 2) {
              this.$router
                .push({
                  path: "order",
                  query: { order: data.order.id, check: data.order.check },
                })
                .catch(() => {});
            } else if (data.image) {
              this.modalImage = true;
              this.image = data.image;
            }
          }
        });
      } else if (data.check === 0 && data.status !== 0) {
        if (data.status !== 2) {
          this.$router
            .push({
              path: "order",
              query: { order: data.order.id, check: data.order.check },
            })
            .catch(() => {});
        } else if (data.image) {
          this.modalImage = true;
          this.image = data.image;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.mobile-notification {
  .v-list-item__title {
    font-size: 16px;
    color: var(--color-second);
    font-weight: 600;
  }

  .v-list-item__subtitle {
    font-size: 13px;
    color: #888 !important;
    margin-top: 2px;
    white-space: normal !important;
    line-height: 20px !important;
    display: block !important;
  }

  .card-title {
    padding: 6px 0px 0px !important;
    font-size: 16px !important;
  }

  .card-subtitle {
    display: flex;
    justify-content: flex-end;
    color: #8b8b8b !important;
    font-size: 12px;
    position: absolute;
    top: 9px;
    right: 16px;
  }

  .v-list-item__action {
    margin-top: 30px !important;

    .icon-warning i {
      color: var(--color-danger) !important;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
    }
  }

  .v-list--three-line .v-list-item .v-list-item__content,
  .v-list-item--three-line .v-list-item__content {
    align-self: center;
  }

  .v-list {
    padding: 4px 0;
  }

  .message-error {
    text-align: center;
    font-size: 18px;
    opacity: 0.4;
    margin-top: 30px;
  }
}
</style>
